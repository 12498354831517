<template>
  <div class="">
    <header class="header-anexar">
      <div class="menu">
        <ul>
          <li>
            <a @click="toHome" class="btClose">
              <i class="fa fa-arrow-left" />
            </a>
          </li>
          <li>
            <h1 class="separator">Anexar Fichas</h1>
          </li>
        </ul>
      </div>
    </header>

    <main class="container-fluid">
      <div class="row justify-content-center mt-3">
        <div class="col-12  col-sm-6">
          <label> <i class="fas fa-user"></i> Selecione o Paciente</label>
          <select-paciente
            @paciente="setPaciente"
            :selecionado="pacienteSelecionado.id"
            setNullOnEmpty
            :showBotaoAdd="false"
            :showBotaoEdit="false"
            :tipo="['paciente']"
            customClass="justify-content-center"
          ></select-paciente>
        </div>
      </div>

      <div
        class="row justify-content-center mt-3 mx-sm-3"
        v-if="!!pacienteSelecionado.id"
      >
        <div class="col-11 col-sm-6  border shadow p-3">
          <b-avatar :src="pacienteSelecionado.foto_carregada" size="100px">
            <template #default>
              <b-spinner v-if="loadingImage"></b-spinner>
              <b-icon
                scale="3rem"
                v-else-if="!pacienteSelecionado.foto_carregada"
                icon="person-fill"
              ></b-icon>
              <img
                class="img-fluid"
                v-else
                :src="pacienteSelecionado.foto_carregada"
                alt=""
              />
            </template>
          </b-avatar>

          <div class="row mt-2 border-top pt-2">
            <div class="col-12 col-sm-6">
              <div><b>Nome: </b> {{ pacienteSelecionado.razao_social }}</div>
            </div>
            <div class="col-12 col-sm-6">
              <div><b>CPF: </b> {{ pacienteSelecionado.cpf }}</div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <b-btn
            @click="openAnexar"
            size="lg"
            variant="primary"
            class="rounded mt-3"
          >
            <i class="fas fa-camera"></i> Anexar Ficha
          </b-btn>
        </div>
      </div>

      <div v-else class="row text-center justify-content-center mt-3">
        <div class="col">
          <h5 class="text-muted">Selecione um paciente para continuar...</h5>
        </div>
      </div>
    </main>

    <b-modal id="modal-anexos" hide-footer>
      <template #modal-title>
        <i class="fas fa-camera"></i> Anexar Ficha | <b>{{ pacienteNome }}</b> |
      </template>
      <anexos-form
        :pacienteNome="pacienteSelecionado.razao_social"
        isFicha
        :id_relacionamento="pacienteSelecionado.id"
        tipo="Entidades"
        @close="closeAnexar"
        @anexou="closeAnexar"
        showButtons
      ></anexos-form>
    </b-modal>
  </div>
</template>

<script>
import SelectPaciente from "../components/common/SelectPaciente.vue";
import AnexosForm from "../components/Anexos/AnexosForm.vue";
import axios from "../utils/axios";
export default {
  components: {
    SelectPaciente,
    AnexosForm,
  },
  data() {
    return {
      pacienteSelecionado: {
        id: null,
        razao_social: null,
        anexos: [],
      },
      loadingImage: false,
    };
  },
  methods: {
    toHome() {
      this.$router.push({ path: "/dashboard" });
      this.$destroy();
    },
    setPaciente(paciente) {
      console.log('setPaciente received', paciente);
      if (paciente && paciente?.id) {
        this.pacienteSelecionado = Object.assign(
          this.pacienteSelecionado,
          paciente
        );
      } else {
        this.pacienteSelecionado = {
          id: null,
          razao_social: null,
        };
      }
      if (this.pacienteSelecionado.foto_entidade) {
        this.loadImage(paciente.foto_entidade);
      }
    },
    async loadImage(filename) {
      // console.log("filename --> ", filename);
      if (filename) {
        this.loadingImage = true;
        const url = `/downloadFile/${filename}`;

        await axios
          .get(url, { responseType: "blob" })
          .then((res) => {
            console.log("res image --> ", res);
            if (!res.data || ![200].includes(res.status)) return;

            this.pacienteSelecionado.foto_carregada = URL.createObjectURL(
              res.data
            );
          })
          .catch((err) => {
            console.log("Erro ao baixar imagem ->", err);
          });
      this.loadingImage = false;
      }
    },
    openAnexar() {
      this.$bvModal.show("modal-anexos");
    },
    closeAnexar() {
      this.$bvModal.hide("modal-anexos");
    },
  },
  computed: {
    pacienteNome() {
      return this.pacienteSelecionado.razao_social;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-anexar {
  display: block;
  background-color: rgb(243, 243, 243);
}
.menu {
  display: flex;
  margin: 0 20px;

  ul {
    display: flex;
    flex-grow: 0.5;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    align-items: center;
  }

  li {
    display: flex;
    list-style: none;
  }
}
.btClose {
  display: block;
  font-size: 0.5rem;
  width: 40px !important;
  height: 40px;
  background-color: #f8f8f8;
  border-radius: 50%;
  padding: 1px;
  text-align: center;
  font-size: 25px;
  color: rgb(0, 0, 0);
  transition: 0.2s all ease;
  &:hover {
    background-color: #ccc;
    box-shadow: 0px 0px 10px #00000090;
  }
}
</style>
